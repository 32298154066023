import dayjs from 'dayjs'

export const getInitialMonth = ({
  availableDates,
  date,
  minDate,
}: {
  availableDates: dayjs.Dayjs[]
  date?: dayjs.Dayjs
  minDate?: dayjs.Dayjs
}) => {
  const today = dayjs().local()

  if (date) {
    return date.local()
  }

  if (availableDates && availableDates.length) {
    const firstAvailableDate = dayjs(availableDates[0]).local()
    return firstAvailableDate
  }

  if (minDate && (today.isSame(minDate.local(), 'month') || today.isBefore(minDate.local(), 'month'))) {
    return minDate.local()
  }

  return today
}

export const isDayIncluded = ({
  dates,
  day,
  minDate,
  type,
}: {
  dates: dayjs.Dayjs[]
  day: dayjs.Dayjs
  minDate?: dayjs.Dayjs
  type?: string
}): boolean => {
  if (minDate && day.isBefore(minDate.format('YYYY-MM-DD'), 'day')) {
    return false
  }

  if (!dates && !type) {
    return true
  }

  if (Array.isArray(dates) && !dates.length) {
    return false
  }

  return dates.map(d => d.format('YYYY-MM-DD')).includes(day.format('YYYY-MM-DD'))
}

export const formatDate = ({
  date,
  inputFormat,
  outputFormat,
}: {
  date: dayjs.Dayjs
  inputFormat?: dayjs.OptionType
  outputFormat: string
}) => {
  if (date instanceof dayjs) {
    return (date as dayjs.Dayjs).format(outputFormat)
  } else {
    // eslint-disable-next-line no-restricted-syntax
    return dayjs(date, inputFormat).format(outputFormat)
  }
}

export const normalizeDate = (date: dayjs.Dayjs, inputFormat: dayjs.OptionType) =>
  formatDate({ date, inputFormat, outputFormat: 'YYYY-MM-DD' })

export const getFEDates = (start: dayjs.Dayjs, end: dayjs.Dayjs) => {
  const dates = []
  let curr = start.clone()
  while (curr.isSameOrBefore(end, 'day')) {
    dates.push(curr.format('YYYY-MM-DDTHH:mm:ssZ').toString())
    curr = curr.add(1, 'day')
  }
  return dates
}
