// @ts-strict-ignore
import { ReactNode, useEffect, useLayoutEffect, useMemo, useState } from 'react'

import dayjs from 'dayjs'
import { useRouter } from 'next/router'

import { Heading5, Heading6 } from '~/theme/utils/typography'
import { queryToSearchSettings, searchSettingsToQuery } from '~/utils/filters'
import { dayjsToString, getTimeFromSlot, isSlotToday } from '~/utils/graphqlDataFormatters'
import useCurrency from '~/utils/hooks/useCurrency'

import type { DebouncedUpdate } from '~/components/Checkout/CheckoutPage/CheckoutPageTypes'
import { defaultSearchSettings, RESERVABLE_TYPES, SLOTS_FETCH_INTERVAL } from '~/constants'
import { DateRange, ReservableInput, useAvailableDatesQuery, useSlotsUsageLazyQuery, useSlotsUsageQuery } from '~/generated/graphql'
import { gt } from '~/locale'
import Button from '~/shared/atoms/Button'
import Input from '~/shared/atoms/Input'
import Search, { SearchSettings } from '~/shared/molecules/Search'
import { DEFAULT_DAILY_SLOTS, DEFAULT_SLOTS } from '~/shared/molecules/Search/SearchUtils'

import {
  Actions,
  ButtonIcon,
  PresaleContainer,
  PresaleInputContainer,
  PresaleTitle,
  PriceAfterComma,
  Results,
  SearchWrapper,
  SlotButton,
  Slotlist,
  Wrapper,
} from './SlotSelectionPanelShards'
import { PossibleSlots, ReservableTypesUnion } from './SlotSelectionPanelTypes'
import {
  actionButtonProps,
  addFringeSlots,
  areAnySlotsAvailable,
  DiscountBadge,
  getAvailableDates,
  getCurrentListFragment,
  getDayUsageVariables,
  getListSize,
  getMonthUsageVariables,
  getNewMonth,
  getOneMonthDateRange,
  LastSpotsBadge,
  mapSlotsUsage,
  NoSlotsTodayBoard,
  PrimeTimeBadge,
  SkeletonSlotlist,
  YourSpotBadge,
} from './SlotSelectionPanelUtils'

interface SelectionPanelViewProps {
  header?: ReactNode
  reservableInput: ReservableInput
  fieldsToHide?: Array<string>
  price?: number
  type?: ReservableTypesUnion
  possibleSlots: PossibleSlots
  dateRange: DateRange
  onSubmit?: (params: Required<SearchSettings>) => Promise<unknown>
  prevParams?: SearchSettings
  defaultSlot?: number
  defaultDate?: dayjs.Dayjs
  forceSearchParams?: boolean
  allDaySlots?: boolean
  showPresale?: boolean
  onPresaleChange?: (e: any) => void
  debouncedUpdate?: DebouncedUpdate
  forceUpdateQuery?: boolean
  name?: string
}

const SlotSelectionPanelView = ({
  header,
  reservableInput,
  fieldsToHide,
  price,
  showPresale = false,
  onPresaleChange = () => null,
  onSubmit,
  type = RESERVABLE_TYPES.DAILY,
  allDaySlots,
  possibleSlots = type === RESERVABLE_TYPES.FER ? DEFAULT_SLOTS : DEFAULT_DAILY_SLOTS,
  dateRange: initialDateRange = {
    startsOn: dayjsToString(dayjs.parseZone()),
    endsOn: dayjsToString(dayjs.parseZone().clone().add(1, 'month')),
  },
  prevParams,
  defaultSlot,
  defaultDate,
  forceSearchParams: forceParams,
  forceUpdateQuery,
  debouncedUpdate,
  name,
}: SelectionPanelViewProps) => {
  const router = useRouter()

  const defaultSearch = defaultSearchSettings({
    untypedQuery: router.query,
    defaultSlot,
    defaultDate,
    forceParams,
  })

  const [searchParams, setSearchParams] = useState<SearchSettings>(
    (prevParams ? { ...prevParams } : queryToSearchSettings({ ...router.query, name })) || defaultSearch
  )

  const [findNearest, setFindNearest] = useState<boolean>(!prevParams && !router.query.slot && !router.query.date && forceParams)
  const [dateRange, setDateRange] = useState(initialDateRange)
  const { data: monthData, loading: availableDatesLoading } = useAvailableDatesQuery({
    variables: getMonthUsageVariables(dateRange, reservableInput),
    fetchPolicy: type === RESERVABLE_TYPES.DAILY ? 'cache-first' : 'cache-and-network',
    pollInterval: SLOTS_FETCH_INTERVAL,
  })
  const [submittingSlot, setSubmittingSlot] = useState<number | null>(null)
  const [presaleCodeEntered, setPresaleCodeEntered] = useState<boolean>(false)

  const { data: dayData, loading } = useSlotsUsageQuery({
    skip: !searchParams?.date,
    variables: !searchParams?.date
      ? getDayUsageVariables(dateRange.startsOn, reservableInput)
      : getDayUsageVariables(dayjsToString(searchParams?.date), reservableInput),
    fetchPolicy: type === RESERVABLE_TYPES.DAILY ? 'cache-first' : 'cache-and-network',
    pollInterval: SLOTS_FETCH_INTERVAL,
  })

  const fetchedSlots = dayData?.slots
  const interval = fetchedSlots && fetchedSlots[0]?.interval
  const showAllSlots = fieldsToHide.includes('slot') || allDaySlots
  const slotlist = showAllSlots ? possibleSlots : addFringeSlots(possibleSlots, interval)

  const slotlistWithUsage = useMemo(() => {
    if (dayData) {
      return mapSlotsUsage(fetchedSlots, dayjsToString(searchParams.date), slotlist)
    }
  }, [possibleSlots, dayData, searchParams?.date])

  const [firstSlot, setFirstSlot] = useState(possibleSlots[0])

  useLayoutEffect(() => {
    const selectedSlotIndex = slotlist.indexOf(searchParams.slot)

    const selectedSlotNotAvailable = selectedSlotIndex < 0

    if (selectedSlotNotAvailable) {
      setFirstSlot(possibleSlots[0])
    } else {
      setFirstSlot(searchParams.slot)
    }
  }, [searchParams?.slot])

  const handleSubmit = async (searchParams: SearchSettings) => {
    setSubmittingSlot(searchParams.slot)
    try {
      await onSubmit(searchParams as Required<SearchSettings>)
    } finally {
      setSubmittingSlot(null)
    }
  }

  useEffect(() => {
    const { query, pathname } = router

    if (searchParams && query.slug && (type === RESERVABLE_TYPES.DAILY || forceUpdateQuery)) {
      const formattedPathname = pathname.replace('[slug]', query.slug.toString())
      router.push(
        {
          pathname: formattedPathname,
          query: { ...searchSettingsToQuery(searchParams), name },
        },
        undefined,
        { shallow: true }
      )
    }
  }, [searchParams])

  useEffect(() => {
    if (searchParams?.date && type !== RESERVABLE_TYPES.FER) {
      const newDateRange = getOneMonthDateRange(searchParams.date.clone())
      setDateRange(newDateRange)
    }
    setSubmittingSlot(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams?.date])

  const listSize = getListSize(showAllSlots, possibleSlots)
  const currency = useCurrency()

  const renderSlotlist = () => {
    const listFragment = showAllSlots ? slotlistWithUsage || [] : getCurrentListFragment(firstSlot, slotlistWithUsage, listSize)
    return listFragment.map(({ sizes, slot, runningOut, discount, primeTimeFee }) => {
      const isLoading = loading || submittingSlot === slot
      const isDisabled = loading || !sizes.length || sizes.at(-1) < (searchParams.peopleCount ?? defaultSearch.peopleCount)
      const isToday = isSlotToday(slot)
      const isPrimeTime = primeTimeFee > 0
      const isPrevSlot =
        prevParams?.slot === slot &&
        prevParams?.date.clone().isSame(searchParams.date.clone(), 'day') &&
        prevParams.peopleCount === searchParams.peopleCount
      const priceToShow = isPrimeTime ? price + primeTimeFee : price
      const [priceMain, priceAfterComma] = priceToShow?.toFixed(2).split('.') || []

      const slotButtonLabel = (
        <>
          <Heading6 fontWeight='semiBold'>
            {getTimeFromSlot(slot)}
            {isPrevSlot && <YourSpotBadge />}
            {!isPrevSlot && !isDisabled && runningOut && <LastSpotsBadge />}
            {!isPrevSlot && !isDisabled && isPrimeTime && <PrimeTimeBadge />}
            {!isPrevSlot && !isDisabled && !runningOut && discount > 0 && <DiscountBadge discountValue={discount} />}
          </Heading6>
          {!isNaN(price) && isToday && (
            <Heading6 fontWeight='regular'>
              <span>
                {price > 0 && `${searchParams.peopleCount} x ${priceMain}`}
                {priceAfterComma && priceAfterComma !== '00' && <PriceAfterComma>{priceAfterComma}</PriceAfterComma>} {currency}
              </span>
              {!isPrevSlot && <ButtonIcon icon='arrow-short' />}
            </Heading6>
          )}
        </>
      )
      return (
        <SlotButton
          height='small'
          width='full'
          justify='space-between'
          color={isPrevSlot ? 'secondary' : 'orange'}
          disabled={isDisabled && !isPrevSlot}
          label={slotButtonLabel}
          key={slot}
          loading={isLoading && !isPrevSlot}
          onClick={() => handleSubmit({ ...searchParams, slot })}
        />
      )
    })
  }
  useEffect(() => {
    setSubmittingSlot(null)
    dayData && renderSlotlist()
    debouncedUpdate && debouncedUpdate({ forceUpdatePreview: true }, { peopleCount: searchParams.peopleCount })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams?.peopleCount, dayData])

  const [fetchCalendarData, { data: calendarData, loading: calendarLoading }] = useSlotsUsageLazyQuery()
  const [currentCalendarDate, setCurrentCalendarDate] = useState(searchParams.date.clone() ?? defaultSearch.date)

  const onCalendarClick = (click: 'prev' | 'next') => {
    if (type === RESERVABLE_TYPES.FER) return

    const newMonth = getNewMonth(currentCalendarDate, click)
    const newDateRange = getOneMonthDateRange(newMonth)

    if (newMonth.isSameOrAfter(dayjs.parseZone())) {
      fetchCalendarData({
        variables: getMonthUsageVariables(newDateRange, reservableInput),
      })
    }

    setCurrentCalendarDate(newMonth)
  }

  const getUsedSlots = (type: ReservableTypesUnion) => {
    if (type === RESERVABLE_TYPES.FER) {
      return monthData?.slots
    }
    return calendarData?.slots ?? monthData?.slots
  }

  const [availableDates, setAvailableDates] = useState(null)

  useLayoutEffect(() => {
    const usedSlots = getUsedSlots(type)
    const availableDates = getAvailableDates(usedSlots)
    setAvailableDates(availableDates)
  }, [monthData, calendarData?.slots])

  const search = (
    <Search
      variant={showAllSlots ? 'equal-width' : 'expand-last'}
      hideOnSelect
      value={searchParams}
      onChange={setSearchParams}
      minDate={dayjs.parseZone()}
      fieldsToHide={fieldsToHide}
      availableDates={availableDates}
      selectableSlots={possibleSlots}
      onCalendarPrevClick={() => onCalendarClick('prev')}
      onCalendarNextClick={() => onCalendarClick('next')}
      calendarLoading={calendarLoading}
      hideYear={true}
      forceSearchParams={forceParams}
      loading={loading}
      orientationBreakpoint={'3000px'}
      minPeopleCount={prevParams?.peopleCount || defaultSearch.peopleCount}
    />
  )

  useLayoutEffect(() => {
    if (availableDates?.length && findNearest && !searchParams?.date.isSame(availableDates[0])) {
      setSearchParams(params => ({ ...params, date: availableDates[0] }))
    }
    if (fetchedSlots?.length && findNearest) {
      const slot = fetchedSlots.find(({ sizes }) => sizes.length)
      setSearchParams(params => ({
        ...params,
        date: dayjs.parseZone(slot?.date),
        slot: slot?.slot,
        peopleCount: params.peopleCount ?? defaultSearch.peopleCount,
      }))
      setFindNearest(false)
    }
  }, [availableDates, findNearest, fetchedSlots])

  const noSlotsToday = !areAnySlotsAvailable(slotlistWithUsage)
  const renderSlotlistContent = () => {
    if (showPresale && !presaleCodeEntered) {
      return null
    }

    if (!loading && noSlotsToday && !availableDatesLoading) {
      return <NoSlotsTodayBoard />
    }

    if (!fetchedSlots || loading) {
      return <SkeletonSlotlist listSize={listSize} />
    }

    if (fetchedSlots && !loading && slotlistWithUsage?.length > 0) {
      return renderSlotlist()
    }
    return null
  }

  return (
    <Wrapper type={type}>
      <SearchWrapper>{header ?? search}</SearchWrapper>
      <Results>
        {header ? search : null}
        {!fieldsToHide.includes('title') && (
          <Heading5 fontWeight='semiBold'>
            {prevParams ? gt.tp('SlotSelection', 'Move a reservation') : gt.tp('SlotSelection', 'Book a table')}
          </Heading5>
        )}
        {showPresale && (
          <PresaleContainer>
            <PresaleTitle>
              {gt.tp('SlotSelection', 'Enter early reservation code or the code received in the Magenta Moments Program.')}
            </PresaleTitle>
            <PresaleInputContainer>
              <Input height={'normal'} width={'full'} onChange={onPresaleChange} disabled={presaleCodeEntered} />
              <Button
                label={presaleCodeEntered ? gt.tp('SlotSelection', 'Edit') : gt.tp('SlotSelection', 'Confirm')}
                color='orange'
                onClick={e => {
                  setPresaleCodeEntered(prev => !prev)
                }}
              />
            </PresaleInputContainer>
          </PresaleContainer>
        )}
        <Slotlist>{renderSlotlistContent()}</Slotlist>
        {!showAllSlots && (
          <Actions>
            <Button
              onClick={() => {
                setSearchParams(p => ({
                  ...p,
                  slot: slotlist.at(Math.max(0, slotlist.indexOf(firstSlot) - listSize)),
                }))
              }}
              disabled={noSlotsToday || firstSlot <= possibleSlots[0]}
              label={['#arrow', gt.tp('SlotSelection', 'Earlier')]}
              {...actionButtonProps}
            />
            <Button
              onClick={() =>
                setSearchParams(p => ({
                  ...p,
                  slot: slotlist.at(Math.min(slotlist.length - listSize, slotlist.indexOf(firstSlot) + listSize)),
                }))
              }
              disabled={noSlotsToday || firstSlot >= possibleSlots[possibleSlots.length - listSize]}
              label={[gt.tp('SlotSelection', 'Later'), '#arrow']}
              {...actionButtonProps}
            />
          </Actions>
        )}
      </Results>
    </Wrapper>
  )
}

export default SlotSelectionPanelView
